import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authentication/authConfig";
import { jwtDecode } from "jwt-decode";
import { Constants } from "@/constants";

const verifyToken = async () => {
  const token = sessionStorage.getItem("idToken");

  const decodedToken = jwtDecode(token);

  const expirationTime = decodedToken.exp * 1000 - 60000;

  if (Date.now() >= expirationTime) {
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    const request = {
      scopes: ["User.Read"],
      account: activeAccount || accounts[0],
    };

    try {
      const response = await msalInstance.acquireTokenSilent(request);
      sessionStorage.setItem("idToken", response.idToken);
    } catch (e) {
      console.log(e);
      console.log("**** ERROR GETTING USER SIGNED IN  ****");
      const homeAccountId = accounts[0].homeAccountId;
      const logoutRequest = {
        account: msalInstance.getAccountByHomeId(homeAccountId),
        postLogoutRedirectUri: msalConfig.auth.redirectUri,
      };
      await msalInstance.logoutRedirect(logoutRequest);
    }
  }
};

const apiRequest = async (method, url, data = null, content_type) => {
  try {
    console.log("Verifying token");
    console.log("services ln 44 URL HERE", Constants.apiUrl);
    console.log("services ln 44 url parameter HERE", url);
    const token = sessionStorage.getItem("access_token");

    const response = await axios({
      timeout: 300000,
      method,
      url: `${Constants.apiUrl}/${url}`,
      data,
      headers: {
        "Content-Type": content_type,
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(`Error with ${method} request to ${url}:`, error);
    if (error.response) {
      return { ...error.response, type: "response" };
    } else if (error.request) {
      return {
        data: { message: "No response from server" },
        status: 500,
        type: "request",
      };
    } else {
      return {
        data: { message: "Error setting up request" },
        status: 500,
        type: "other",
      };
    }
  }
};

export const sendRequest = async (
  data,
  url,
  method = "post",
  content_type = "application/json"
) => {
  return await apiRequest(method, url, data, content_type);
};
