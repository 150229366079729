import { columns } from "@/components/column";
import { DataTable } from "@/components/datatable";
import { useEffect, useState } from "react";
import { sendRequest } from "../services/apiService";
import { Plus, PlusCircle } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useForm, reset } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";

export default function NewCrud() {
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  useEffect(() => {
    const setResponse = async () => {
      const response = await sendRequest(null, "prompts", "get");
      setData(response.data);
    };
    setResponse();
  }, []);
  useEffect(() => {
    const getCategories = async () => {
      const response = await sendRequest(null, "get_categories", "get");
      setCategories(response.data);
    };
    getCategories();
  }, [isDialogOpen]);
  const onDelete = async (id) => {
    const response = await sendRequest({ prompt_id: id }, "prompts", "delete");
    const newResponse = await sendRequest(null, "prompts", "get");
    setData(newResponse.data);
  };
  const onSubmit = async (data) => {
    const response = await sendRequest(data, "prompts", "post");
    if (response.status == 200) {
      setShow(false);
      const response = await sendRequest(null, "prompts", "get");
      setData(response.data);
    }
  };
  const handleAddCategory = async () => {
    const data = { category: newCategory };
    const response = await sendRequest(data, "add_category", "post");
    if (response.status == 200) {
      setShow(false);
      const response = await sendRequest(null, "get_categories", "get");
      setCategories(response.data);
    }
  };
  return (
    <div className="container mx-auto py-10 overflow-auto text-black">
      <Dialog open={show} onOpenChange={setShow}>
        <DialogTrigger>
          {" "}
          <Plus className="rounded w-10 h-10 m-2 hover:bg-slate-300 p-2" />
        </DialogTrigger>
        <DialogContent className="space-6 text-black">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogHeader className="my-2">
              <DialogTitle className="my-2">New Prompt</DialogTitle>
              <DialogDescription className="flex flex-col space-y-6">
                <Input
                  {...register("title")}
                  Label="Title"
                  placeholder="Enter title"
                  errors={errors}
                  inputId="title"
                />
                <Textarea
                  {...register("prompt")}
                  Label="Prompt"
                  placeholder="Enter Prompt"
                  errors={errors}
                  inputId="prompt"
                />
                <Select
                  inputId="category"
                  onValueChange={(e) => setValue("category", e)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a category" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {categories.map((category) => (
                        <SelectItem value={category}>{category}</SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                  <DialogTrigger asChild>
                    <Button type="button" variant="outline" size="icon">
                      <PlusCircle className="h-4 w-4" />
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Add New Category</DialogTitle>
                    </DialogHeader>
                    <div className="flex items-center space-x-2">
                      <Input
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        placeholder="New category name"
                      />
                      <Button onClick={handleAddCategory}>Add</Button>
                    </div>
                  </DialogContent>
                </Dialog>
              </DialogDescription>
            </DialogHeader>
            <DialogFooter className="flex space-x-5">
              <Button disabled={isSubmitting} label="Submit" type="submit">
                Submit
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
      <DataTable
        columns={columns}
        data={data}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    </div>
  );
}
